<template>
  <div v-bind:class="{'active-item': currentPath.path===node.path  && node.path!=='/'}" style="padding-left: 2px;">
    <li v-show="!root" @contextmenu.prevent="openMenuElement($event,node)"
    :style="{'margin-left':`${depth * 10}px`}">
        <div class="row">
            <div class="col-1 float-rigth mt-1">
                <i class="icon-expand fa fa-caret-right" v-if="!expanded && node.hasSubdirectories" @click="expand"></i>
                <i class="icon-expand fa fa-caret-down" v-if="expanded && node.hasSubdirectories" @click="compress"></i>
            </div>
            <div class="col-10 pl-0">
                <a href="#" @click="toFolder(node.path)">
                    <i class="fa fa-folder"></i> {{node.basename}}
                </a>
            </div>
        </div>
    </li>
    <tree-view 
        v-show="node.children.length>0 && expanded"
        v-for="child in node.children"
        :ref="node.path.replace(/\s/g, '')"
        :root="false"
        :key="child.id"
        :node="child"
        :currentPath="currentPath"
        :updateSection="updateSection"
        :depth="root?0:depth + 1"
        v-on:toFolder="toFolder($event)"
        v-on:emitOpenMenuElement="emitOpenMenuElement($event)"
    />
  </div>
</template>

<script>

import { VueContext } from 'vue-context';
import documentManager from "@/services/api/documentManager";

export default {
  name: 'tree-view',
  props:{
    node: {
        type: Object,
        default: function () {
            return {
                basename:null,
                children:[],
                path:'/',
                hasSubdirectories:false
            }
        }      
    },
    currentPath:{
        type: Object,
        default: function() {
            return {
                currentPath:{
                    parent:{
                        parent:null,
                        path:null
                    },
                    path:null
                }
            }
        }
    },
    updateSection:{
        type: Object,
        default: function() {
            return {
                content:[],
                path:null
            }
        }
    },
    depth: {
        type: Number,
        default: 0,
    },
    root: Boolean
  },
  data() {
    return {
        expanded: false,
    };
  },
  mounted (){
    if(this.root){
        this.expanded=true;
    }
  },
  methods: {
    emitOpenMenuElement(event){
        this.$emit("emitOpenMenuElement", {event:event.event,item:event.item});
    },
    openMenuElement($event,item){
        this.$emit("emitOpenMenuElement", {event:$event,item:item});
    },
    expand(){
        if(this.node.children.length==0){
            documentManager.tree({path:this.node.path}).then(response => {
                response.directories.forEach(element => {
                    element.children=[];
                });
                this.node.children=response.directories;
            });
        }
        this.expanded=true;
    },
    compress(){
        this.expanded=false;
    },
    toFolder (event){
        this.expand();
        this.$emit("toFolder", event);
    }
  },
  watch: { 
        updateSection: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if(newValue.path===this.node.path){
                    this.node.children=newValue.content;
                    if(this.node.children.length>0){
                        this.node.hasSubdirectories=true;
                    }else{
                        this.node.hasSubdirectories=false;
                    }
                }
            }
        }
      }
};
</script>

<style lang="scss">
.icon-expand{
    cursor:pointer;
}

.active-item{
    background-color: #eeeeee;
}
</style>
